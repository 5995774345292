import * as React from 'react';
import { graphql } from 'gatsby';

import { getSeries } from 'ducks/series';
import {
  useAuthenticationUser, useDispatch, useDevice,
} from 'services/hooks';

import { Template } from  'common/layout';
import {
  ClassesBlock, CheckoutModal, CheckoutProvider, PricingHeader,
  MembershipsBlock, MembershipsBlockMobile,
} from 'modules/PricingPage';

const PricingPage = () => {
  const dispatch = useDispatch();
  const { determined } = useAuthenticationUser();
  const { isMobile } = useDevice();

  React.useEffect(() => {
    if (determined) {
      dispatch(getSeries('amsterdam'));
    }
  }, [determined]);

  return (
    <Template>
      <CheckoutProvider>
        <PricingHeader />
        {isMobile ? (
          <MembershipsBlockMobile />
        ) : (
          <MembershipsBlock />
        )}
        <ClassesBlock />
        <CheckoutModal />
      </CheckoutProvider>
    </Template>
  );
};

export const query = graphql`
  query Pricing($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          language
          data
        }
      }
    }
  }
`;

export default PricingPage;
